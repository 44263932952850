import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

axios.defaults.baseURL = 'https://elezioni.liceoeinsteinservizi.com'; // production
// axios.defaults.baseURL = "http://localhost:8000"; // test

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
