<template>
    <div id="app" class="text">
        <header v-if="showHeader">
            <h1 id="main-header">elezioni online</h1>
            <hr class="border-colored" />
            <p class="colored" id="main-subtitle">
                presso il Liceo Einstein
                <span class="tp:d-none"> di Milano</span>
                <span class="with-room">&mdash;</span> A.S.: 2023/2024
            </p>
        </header>
        <main class="main">
            <transition name="fade" mode="out-in">
                <router-view />
            </transition>
        </main>
    </div>
</template>

<script>
export default {
    name: 'App',
    computed: {
        showHeader() {
            return this.$store.state.globalUtilities.showHeader;
        },
    },
};
</script>

<style lang="scss">
#main-header {
    color: $dim;
    margin-top: 5rem;
    line-height: 13rem;
    font-family: $roboto-slab;
    font-size: 10rem;
    text-transform: capitalize;
    @include respond(tab-port) {
        line-height: 8rem;
        font-size: 6rem;
    }
    @include respond(phone) {
        line-height: 6rem;
        font-size: 4.3rem;
        margin-top: 3.5rem;
    }
}

#main-subtitle {
    margin-top: 0.7rem;
    font-family: $montserrat;
    font-size: 3rem;
    text-align: right;
    @include respond(tab-port) {
        font-size: 2rem;
    }
    @include respond(phone) {
        font-size: 1.8rem;
    }
}

#app {
    margin: 0 6rem;
    @include respond(tab-port) {
        margin: 0 2rem;
    }
    @include respond(phone) {
        margin: 0 1rem;
    }
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $text-color;
}

.main {
    margin: 8rem 0;
    @include respond(phone) {
        margin: 5rem 0;
    }
}

.with-room {
    margin: 0 1rem;
}

.colored {
    color: $secondary;
}

.border-colored {
    border-top: 1px solid $secondary;
    border-bottom: 0;
}

.mb-large {
    margin-bottom: 8rem;
}

#nav {
    font-family: $montserrat;
    font-size: 2.4rem;
    text-align: center;
    padding: 30px;

    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.4s ease-out;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
